import React, {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import {
  getUserGeo,
  makeContactPayDeposit,
  makeDeposit,
} from '../../../../api';
import { ModalContext } from '../../../../components';
import { removeZeroesAfterComma } from '../../../../helpers';
import { Button, InputNumber, Typography } from '../../../../ui-kit';
import { HorizontalDivider } from '../../../../ui-kit/Dividers/HorizontalDivider';
import { Headline } from '../../../../ui-kit/Typography/Headline';
import { useStyle } from './Deposit.style';

const ConfirmContent: React.FC<{
  onConfirmClick: () => void;
  amountGc: number;
}> = memo(({ onConfirmClick, amountGc }) => {
  const { classes } = useStyle();
  const { Text } = Typography;

  return (
    <div>
      <div className={classes.confirmContentAmount}>
        <Text>{amountGc} GC</Text>
        <Text>{amountGc} Руб.</Text>
      </div>
      <div className={classes.confirmContentTax}>
        <div>
          <Text variant="b4" color="secondary">
            Сбор GG
          </Text>
          <Text variant="b4">+ 0.1 Руб.</Text>
        </div>
        <div>
          <Text variant="b4" color="secondary">
            Налог
          </Text>
          <Text variant="b4">+ 0.1 Руб.</Text>
        </div>
      </div>
      <HorizontalDivider />
      <div className={classes.confirmContentFooter}>
        <div>
          <Text variant="b5" color="secondary" block>
            Итого
          </Text>
          <Text color="primary">{amountGc} Руб.</Text>
        </div>
        <Button onClick={onConfirmClick}>Подтвердить</Button>
      </div>
    </div>
  );
});

export const Deposit = () => {
  const [amountGc, setAmountGc] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { Text } = Typography;
  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isContactPay = useMemo(() => geo?.country === 'RU', [geo?.country]);

  const {
    balanceActionConfirm: {
      onOpenBalanceActionConfirm,
      onCloseBalanceActionConfirm,
    },
  } = useContext(ModalContext);

  const [paymentMethod, setPaymentMethod] = useState('card_acquiring_rub');

  const currency = useMemo(
    () => (isContactPay ? 'Руб' : 'USD'),
    [isContactPay]
  );
  const currencyFactor = useMemo(
    () => (isContactPay ? 10 : 1000),
    [isContactPay]
  );
  const taxFactor = useMemo(
    () => (isContactPay ? 0 : 0.000025),
    [isContactPay]
  );
  const taxFactorGG = useMemo(
    () => (isContactPay ? 0 : 0.00003),
    [isContactPay]
  );

  const depos = useCallback(
    (amount: number) => {
      if (isContactPay) {
        return makeContactPayDeposit(amount, paymentMethod);
      }
      return makeDeposit(amount);
    },
    [geo?.country, paymentMethod]
  );

  const onConfirmClick = async () => {
    onCloseBalanceActionConfirm();

    const response = await depos(amountGc);

    if (response) {
      window.location.href = response;
    }
  };

  const onDepositClick = () => {
    onOpenBalanceActionConfirm(
      'Подтверждение покупки',
      <ConfirmContent onConfirmClick={onConfirmClick} amountGc={amountGc} />
    );
  };

  const limit = useMemo(() => (isContactPay ? 1000 : 2000), [isContactPay]);

  const PaymentButton = ({
    getImg,
    name,
    description,
    isActive,
    onClick,
  }: {
    getImg: () => ReactNode;
    name: string;
    description: string;
    isActive: boolean;
    onClick: () => void;
  }) => {
    return (
      <div
        className={`${classes.paymentButtom} ${
          isActive ? classes.activeBorder : ''
        }`}
        onClick={onClick}
        key={name}
      >
        <div
          className={`${classes.paymentImageContainer} ${
            isActive ? classes.activeBorder : ''
          }`}
        >
          {getImg()}
        </div>
        <div className={classes.paymentTexts}>
          <div>
            <Text block variant="b6">
              {name}
            </Text>
            <Text
              variant="b8"
              block
              color="typoPlaceholder"
              className={classes.paymentDescription}
            >
              {description}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const paymentButtons = useMemo(
    () => [
      {
        img: (
          <img width="25px" height="26px" src="/images/profile/card.png"></img>
        ),
        name: 'profile.depositModal.paymentCard',
        description: 'profile.depositModal.paymentCardDescription',
        isActive: paymentMethod === 'card_acquiring_rub',
        onClick: () => setPaymentMethod('card_acquiring_rub'),
      },
      /*
      {
        img: (
          <img
            width="29px"
            height="16px"
            src="/images/profile/sbp_white.png"
          ></img>
        ),
        name: 'profile.depositModal.paymentSbp',
        description: 'profile.depositModal.paymentSbpDescription',
        isActive: paymentMethod === 'sbp_rub',
        onClick: () => setPaymentMethod('sbp_rub'),
      },
      */
    ],
    [paymentMethod]
  );

  return (
    <div className={classes.depositContainer}>
      <Headline className={classes.title} variant="h2">
        Пополнить баланс
      </Headline>
      <Text variant="b5" className={classes.supportText}>
        Пополнить баланс можно только российскими рублями. Если возникнут
        вопросы, напишите нам в{' '}
        <Text variant="b5" color="link" decoration="d1">
          Discord
        </Text>
        .
      </Text>
      <Text
        variant="b6"
        block
        color="typoPlaceholder"
        className={classes.paymentDescription}
      >
        {translation('profile.depositModal.paymentType')}
      </Text>
      <div className={classes.paymentButtoms}>
        {paymentButtons.map(({ description, img, isActive, name, onClick }) => {
          return (
            <PaymentButton
              key={name}
              getImg={() => img}
              name={translation(name)}
              description={translation(description)}
              isActive={isActive}
              onClick={onClick}
            />
          );
        })}
      </div>
      <div className={classes.inputContainer}>
        <InputNumber
          title="GC"
          placeholder={`${limit} GC`}
          suffix=" GC"
          className={classes.input}
          value={amountGc}
          decimalScale={2}
          onValueChange={({ value }) => {
            setAmountGc(+value);
          }}
          onBlur={() => {
            if (amountGc < limit) {
              setError(`Minimum ${limit} GC`);
            } else {
              setError('');
            }
          }}
          error={error}
        />
        <Text variant="b5" block>
          =
        </Text>
        <InputNumber
          title={currency}
          placeholder={` ${currency}`}
          suffix={` ${currency}`}
          decimalScale={2}
          className={classes.input}
          value={amountGc / currencyFactor}
          onValueChange={({ value }) => {
            setAmountGc(+value * currencyFactor);
          }}
          onBlur={() => {
            if (amountGc < limit) {
              setError(`Minimum ${limit} GC`);
            } else {
              setError('');
            }
          }}
        />
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.taxContainer}>
          <div className={classes.taxDataContainer}>
            <Text variant="b6" block color="typoLabel">
              {translation('profile.depositModal.fee')}
            </Text>
            <Text block variant="b5">
              {`+ ${removeZeroesAfterComma(
                (amountGc * taxFactor).toFixed(2)
              )} Руб.`}
            </Text>
          </div>
          <div className={classes.taxDataContainer}>
            <Text variant="b6" block color="typoLabel">
              {translation('profile.depositModal.tax')}
            </Text>
            <Text block variant="b5">
              {`+ ${removeZeroesAfterComma(
                (amountGc * taxFactorGG).toFixed(2)
              )} Руб.`}
            </Text>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buyBtn}
            disabled={!!error || amountGc < limit}
            onClick={onDepositClick}
          >{`${translation('profile.depositModal.btn')}  ${(
            (amountGc / currencyFactor) *
            (1 + (taxFactorGG + taxFactor) * 1000)
          ).toFixed(2)} Руб.`}</Button>
        </div>
      </div>
    </div>
  );
};
