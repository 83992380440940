import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import { buyBattlepass, getBalance, getUserGeo, withdrawFiat } from 'api';
import { useClickOutside } from 'hooks';
import { Button, Card, Input, InputNumber, Typography } from 'ui-kit';

import { Headline } from '../../../../ui-kit/Typography/Headline';
import { ModalContext } from '../../ModalProvider';
import { Modal } from '../Modal';
import { useStyle } from './WithdrawFiat.styles';

interface IWithdrawProps {
  isOpen: boolean;
  onClose: () => void;
}

const defaultValues = {
  pan: '',
  holder: '',
  month: null,
  year: null,
  amount: null,
};

type WithdrawTypes = typeof defaultValues;

const WithdrawSteam = () => {
  const { Text } = Typography;
  const { classes } = useStyle();
  const {
    walletError: { openWalletError },
    battlepass: { openBattlepass },
  } = useContext(ModalContext);
  const buy = async (amount: 2500 | 5000 | 10000) => {
    try {
      const promo = await buyBattlepass(amount);
      openBattlepass(promo);
    } catch (e) {
      openWalletError();
    }
  };
  return (
    <>
      <Text variant="b4" className={classes.battlepassBlockText} block>
        Все промокоды обмениваются на сайте{' '}
        <a href="https://battlepass.ru/gift/activate">
          <Text variant="b6" color="system">
            BATTLE PASS
          </Text>
        </a>
      </Text>

      <div className={classes.battlepassBlock}>
        <Card variant="p24" color="bgGhost">
          <div>
            <Text variant="b1" block>
              2500 GC
            </Text>
            <Text variant="b5" color="typoPlaceholder" block>
              250р
            </Text>
            <Button
              className={classes.battlepassButton}
              onClick={() => buy(2500)}
            >
              Выбрать
            </Button>
          </div>
        </Card>
        <Card variant="p24" color="bgGhost">
          <div>
            <Text variant="b1" block>
              5000 GC
            </Text>
            <Text variant="b5" color="typoPlaceholder" block>
              500р
            </Text>
            <Button
              className={classes.battlepassButton}
              onClick={() => buy(5000)}
            >
              Выбрать
            </Button>
          </div>
        </Card>
        <Card variant="p24" color="bgGhost">
          <div>
            <Text variant="b1" block>
              10000 GC
            </Text>
            <Text variant="b5" color="typoPlaceholder" block>
              1000р
            </Text>
            <Button
              className={classes.battlepassButton}
              onClick={() => buy(10000)}
            >
              Выбрать
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export const WithdrawFiat: FC<IWithdrawProps> = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  const { classes } = useStyle();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const { data: geo } = useSWR('api/geo', getUserGeo);

  useClickOutside(ref, onClose);
  const { Text } = Typography;
  const { data: balanceData } = useSWR('api/balance');
  const methods = useForm<WithdrawTypes>({ defaultValues });
  const {
    formState: { errors },
    control,
    register,
    getValues,
    trigger,
    setError,
    clearErrors,
    reset,
  } = methods;
  const pan = useWatch<WithdrawTypes>({ name: 'pan', control });
  const amount = useWatch<WithdrawTypes>({ name: 'amount', control });

  const {
    walletSuccess: { openWalletSuccess },
    walletError: { openWalletError },
  } = useContext(ModalContext);

  const onWithdraw = async (): Promise<void> => {
    if (errors['amount']) {
      return;
    }
    const isValid = await trigger(['pan', 'amount', 'year', 'month', 'holder']);
    if (!isValid) {
      return;
    }
    const { amount, year, month, ...rest } = getValues();
    setIsButtonDisabled(true);
    const response = await withdrawFiat({
      month: Number(month),
      year: Number(year),
      amount: Number(amount),
      ...rest,
    });
    if (response.status === 200) {
      onClose();
      openWalletSuccess();
      reset(defaultValues);
      setIsButtonDisabled(false);
      mutate('api/balance', getBalance);
    } else {
      onClose();
      reset(defaultValues);
      setIsButtonDisabled(false);
      openWalletError();
    }
  };

  useEffect(() => {
    const balance = balanceData?.balance || 0;
    if (amount > balance) {
      setError('amount', {
        type: 'custom',
        message: translation('profile.withdraw.amountInsuffError'),
      });
    } else {
      clearErrors('amount');
    }
  }, [amount]);

  const { t: translation } = useTranslation();
  const isRussian = useMemo(() => geo?.country === 'RU', [geo?.country]);

  const ModalContainer: React.FC<{ children: ReactNode }> = useCallback(
    ({ children }) => {
      if (isRussian) {
        return (
          <div ref={ref} className={classes.modal}>
            <Headline className={classes.title} block>
              Вывод средств
            </Headline>
            <div>
              <Text
                className={classes.text}
                color="typoPlaceholder"
                variant="b5"
                block
              >
                Сколько GC вы хотите вывести на ваш Steam-аккаунт? Выберите
                номинал:
              </Text>
            </div>
            {children}
            <Text
              className={classes.textWarning}
              color="typoPlaceholder"
              variant="b5"
              block
            >
              Все промокоды выводятся на сайте{' '}
              <a
                className={classes.discordLink}
                href="https://battlepass.ru/gift/activate"
              >
                Battle-pass
              </a>
            </Text>
          </div>
        );
      }

      return <div ref={ref}>{children}</div>;
    },
    []
  );

  const Form: React.FC = useCallback(() => {
    return (
      <div className={classes.form}>
        <InputNumber
          placeholder={translation('profile.withdraw.pan')}
          title={translation('profile.withdraw.cardTitle')}
          className={classes.addressInput}
          error={errors['pan']?.message}
          onFocus={() => clearErrors('pan')}
          {...register('pan', {
            required: {
              value: true,
              message: translation('profile.withdraw.cardError'),
            },
            minLength: {
              value: 16,
              message: translation('profile.withdraw.cardError'),
            },
            maxLength: {
              value: 16,
              message: translation('profile.withdraw.cardError'),
            },
          })}
        />
        <div className={classes.cardInfo}>
          <div className={classes.cardDate}>
            <InputNumber
              placeholder={translation('profile.withdraw.month')}
              title={translation('profile.withdraw.monthTitle')}
              className={classes.addressInput}
              error={errors['month']?.message as string}
              onFocus={() => clearErrors('month')}
              {...register('month', {
                required: {
                  value: true,
                  message: translation('profile.withdraw.monthError'),
                },
                maxLength: {
                  value: 2,
                  message: translation('profile.withdraw.monthSizeError'),
                },
              })}
            />
            <InputNumber
              placeholder={translation('profile.withdraw.year')}
              title={translation('profile.withdraw.yearTitle')}
              className={classes.addressInput}
              error={errors['year']?.message as string}
              onFocus={() => clearErrors('year')}
              {...register('year', {
                required: {
                  value: true,
                  message: translation('profile.withdraw.yearError'),
                },
                minLength: {
                  value: 4,
                  message: translation('profile.withdraw.yearError'),
                },
                maxLength: {
                  value: 4,
                  message: translation('profile.withdraw.yearError'),
                },
              })}
            />
          </div>
          <Input
            placeholder={translation('profile.withdraw.holder')}
            title={translation('profile.withdraw.holderTitle')}
            className={classes.addressInput}
            error={errors['holder']?.message}
            onFocus={() => clearErrors('holder')}
            {...register('holder', {
              required: {
                value: true,
                message: translation('profile.withdraw.holderError'),
              },
            })}
          />
        </div>
        <div className={classes.footer}>
          <div className={classes.amountContainer}>
            <Controller
              name="amount"
              rules={{
                required: {
                  value: true,
                  message: translation('profile.withdraw.minError'),
                },
                min: {
                  value: 10000,
                  message: translation('profile.withdraw.minError'),
                },
              }}
              render={({ field: { onChange, ...rest } }) => (
                <InputNumber
                  error={errors['amount']?.message as string}
                  placeholder="10 000 GC"
                  title={translation('profile.withdraw.amountTitle')}
                  className={classes.amountInput}
                  onFocus={() => clearErrors('amount')}
                  suffix={' GC'}
                  disabled={!pan}
                  onValueChange={({ value }) => onChange(value)}
                  {...rest}
                />
              )}
            />
            <div className={classes.currencyItem}>
              <Text className={classes.text} color="typoLabel" variant="b6">
                {translation('profile.withdraw.total')}
              </Text>
              <Text className={classes.text} variant="b5">
                {amount >= 10000
                  ? ((amount * 0.97) / 1000 - 0.8).toFixed(2)
                  : 0}{' '}
                USD
              </Text>
            </div>
          </div>
          <Button onClick={onWithdraw} disabled={isButtonDisabled}>
            {translation('profile.withdraw.withdrawBtn')}
          </Button>
        </div>
      </div>
    );
  }, [errors]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
    >
      <ModalContainer>
        <FormProvider {...methods}>
          {isRussian ? <WithdrawSteam /> : <Form />}
        </FormProvider>
      </ModalContainer>
    </Modal>
  );
};
