import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import {
  // getTransactionCount,
  getUserGeo,
  isErrorResponse,
  IUserResponse,
} from 'api';
import { BottomNotification, Card, Typography } from 'ui-kit';

import { useStyle } from '../Profile.styles';
import { Battlepass } from './Battlepass';
import { Deposit } from './Deposit/Deposit';
import { DiscordBanner } from './DiscordBanner';
// import { MoneyBanner } from './MoneyBanner';
import { Person } from './Person';
import { PromoCard } from './PromoCard';
import { PromoCodeSteam } from './PromoCodeSteam/PromoCodeSteam';
import { Wallet } from './Wallet/Wallet';
import { WithdrawFiat } from './WithdrawFiat/WithdrawFiat';

export const Profile: FC = () => {
  const { error, data: user } = useSWR('api/user');
  const { classes } = useStyle();
  const navigate = useNavigate();

  const [balanceAction, setBalanceAction] = useState({
    isManualDepositOpen: false,
    isWithdrawFiatOpen: false,
  });

  useEffect(() => {
    if (error?.status === 403) {
      navigate('/home');
    }
  }, [error]);

  const { Text } = Typography;
  const { t: translation } = useTranslation();
  // const { data: transactions } = useSWR(
  //   'api/transactions',
  //   getTransactionCount
  // );
  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isRussian = geo?.country === 'RU';

  const onOpenManualDeposit = useCallback(() => {
    setBalanceAction((state) => ({
      ...state,
      isManualDepositOpen: true,
      isWithdrawFiatOpen: false,
    }));
  }, [balanceAction]);

  const onOpenWithdrawFiat = useCallback(() => {
    setBalanceAction((state) => ({
      ...state,
      isManualDepositOpen: false,
      isWithdrawFiatOpen: true,
    }));
  }, [balanceAction]);

  return (
    <div className={classes.profileContainer}>
      <div className={classes.profileTopBlock}>
        <Card variant="p24" color="bgGhost" className={classes.userInfoBlock}>
          <Person user={!isErrorResponse(user) && user} />
        </Card>
        <PromoCard />
        <a
          href={
            window.location.origin +
            /*(geo?.country !== 'RU'
              ?*/ '/legal/world/Profile.TermsOfUse.pdf' /*
              : '/legal/ru/TermsOfUse.pdf')*/
          }
          target="_blank"
          rel="noreferrer"
        >
          <Text variant="b5" color="link" className={classes.terms}>
            {translation('profile.term')}
          </Text>
        </a>
      </div>
      <div className={classes.balanceAction}>
        {balanceAction.isWithdrawFiatOpen && <WithdrawFiat />}
        {balanceAction.isManualDepositOpen && <Deposit />}
        {/*balanceAction.isWithdrawFiatOpen && <PromoCodeSteam />*/}
        {!balanceAction.isWithdrawFiatOpen && <DiscordBanner />}
      </div>
      <div className={classes.walletContainer}>
        <Wallet
          balanceAction={balanceAction}
          onOpenManualDeposit={onOpenManualDeposit}
          onOpenWithdrawFiat={onOpenWithdrawFiat}
        />
        {isRussian ? (
          <Card
            variant="p24"
            color="bgGhost"
            className={classes.battlepassInfoBlock}
          >
            <Battlepass user={!isErrorResponse(user) && user} />
          </Card>
        ) : null}
      </div>
      {/* {transactions?.count === 0 && <MoneyBanner />} */}
      <BottomNotification />
    </div>
  );
};
