import { FC, useContext, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import {
  getBalance,
  getChallengesCount,
  ICreatedChallenge,
  isErrorResponse,
  updateChallenge,
  IUserResponse,
  EUserPermissions,
} from 'api';
import { ModalContext } from 'components';
import { CHALLENGE_FORM_DATA_KEY } from 'consts';
import { hasPermissions } from 'helpers/helpers';
import { BackgroundContext } from 'Layout';
import { Button } from 'ui-kit';

import {
  createAndPublishChallenge,
  finishActivateChallenge,
  PUBLISH_ERRORS_TEXT_TRANSLATION,
} from '../../ChallengeView.model';
import { useStyle } from './BottomButtons.styles';

interface IPublishButton {
  challenge: ICreatedChallenge;
}

export const PublishButton: FC<IPublishButton> = ({ challenge }) => {
  const [isPublishing, setIsPublishing] = useState(false);
  const [error, setError] = useState('');
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const navigate = useNavigate();
  const ERRORS_TEXT = PUBLISH_ERRORS_TEXT_TRANSLATION(translation);
  const MAX_CHALLENGE_LIMIT = 5;
  const { data: balance } = useSWR('api/balance', getBalance);
  const { data: user } = useSWR<IUserResponse>('api/user');
  const hasPermission = hasPermissions(user, EUserPermissions.infiniteOwner);
  const isNotEnoughBalance = +balance?.balance < +challenge?.reward;
  const { data: countChallenges } = useSWR(
    'api/challenges/count',
    getChallengesCount
  );
  const { setBackgroundState } = useContext(BackgroundContext);
  const {
    galleryModal: { resetImage },
  } = useContext(ModalContext);
  const isError = +balance?.balance < +challenge?.reward || !!error;
  const isErrorCount = countChallenges?.asOwner >= MAX_CHALLENGE_LIMIT;
  const handlePublishChallengeClick = async (): Promise<void> => {
    const { id, isFetched } = challenge;
    try {
      setIsPublishing(true);
      if (isFetched) {
        await updateChallenge(challenge);
        await finishActivateChallenge(id);
        setIsPublishing(false);
        navigate('/challenges/my/myActive');
        return;
      }
      const createChallengeResponse = await createAndPublishChallenge(
        challenge
      );
      if (!isErrorResponse(createChallengeResponse)) {
        await finishActivateChallenge(createChallengeResponse?.id);
        navigate('/challenges/my/myActive');
        localStorage.removeItem(CHALLENGE_FORM_DATA_KEY);
        setBackgroundState(0);
        mutate('api/balance');
        resetImage();
      }
    } catch (err) {
      console.error('Failed to create draft', err);
      setError(err);
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <>
      <div className={classes.publishBtnContainer}>
        <Button
          buttonSize="l"
          onClick={() => {
            handlePublishChallengeClick();
          }}
          disabled={
            (isErrorCount && !hasPermission) ||
            isPublishing ||
            isNotEnoughBalance
          }
        >
          {isPublishing
            ? translation('challengeView.publishing')
            : translation('challengeView.publish')}
        </Button>
        {isError && (
          <span
            className={clsx(classes.error, isErrorCount && classes.errorCount)}
          >
            {error && ERRORS_TEXT.network}
            {+balance.balance < +challenge.reward && ERRORS_TEXT.money}
          </span>
        )}
      </div>
      {isErrorCount && !hasPermission && (
        <span className={classes.error}>{ERRORS_TEXT.maxLimit}</span>
      )}
    </>
  );
};
