import { FC, memo, useContext } from 'react';

import { buyBattlepass } from '../../../../api';
import { ModalContext } from '../../../../components';
import { Button, Card, Typography } from '../../../../ui-kit';
import { HorizontalDivider } from '../../../../ui-kit/Dividers/HorizontalDivider';
import { Headline } from '../../../../ui-kit/Typography/Headline';
import { useStyle } from './WithdrawFiat.style';

type Amount = 2500 | 5000 | 10000;

const ConfirmContent: React.FC<{
  amount: Amount;
}> = memo(({ amount }) => {
  const { classes } = useStyle();
  const { Text } = Typography;

  const {
    walletError: { openWalletError },
    battlepass: { openBattlepass },
    balanceActionConfirm: { onCloseBalanceActionConfirm },
  } = useContext(ModalContext);

  const buy = async (amount: Amount) => {
    onCloseBalanceActionConfirm();

    try {
      const promo = await buyBattlepass(amount);
      openBattlepass(promo);
    } catch (e) {
      openWalletError();
    }
  };

  return (
    <div>
      <div className={classes.confirmContentAmount}>
        <Text>{amount} GC</Text>
        <Text>{amount} Руб.</Text>
      </div>
      <HorizontalDivider />
      <Button className={classes.confirmContentBtn} onClick={() => buy(amount)}>
        Подтвердить
      </Button>
    </div>
  );
});

export const WithdrawFiat: FC = () => {
  const { Text } = Typography;
  const { classes } = useStyle();
  const {
    balanceActionConfirm: { onOpenBalanceActionConfirm },
  } = useContext(ModalContext);

  const onClickHandler = (amount: Amount) => {
    onOpenBalanceActionConfirm(
      'Подтверждение вывода',
      <ConfirmContent amount={amount} />
    );
  };

  return (
    <div className={classes.withdrawFiatContainer}>
      <Headline className={classes.title} block variant="h2">
        Вывод средств
      </Headline>
      <Text variant="b5" className={classes.supportText}>
        Вывести деньги можно только валютой USD. Если возникнут вопросы,
        напишите нам в{' '}
        <Text variant="b5" color="link" decoration="d1">
          Discord
        </Text>
        .
      </Text>
      <Text className={classes.text} color="typoPlaceholder" variant="b5" block>
        Сколько GC вы хотите вывести на ваш Steam-аккаунт? Выберите номинал:
      </Text>
      <div className={classes.battlepassBlock}>
        <Card variant="p12x16" color="bgGhost">
          <div>
            <Text variant="b1" block>
              2500 GC
            </Text>
            <Text variant="b5" color="typoPlaceholder" block>
              250 Руб.
            </Text>
            <Button
              className={classes.battlepassButton}
              onClick={() => onClickHandler(2500)}
            >
              Выбрать
            </Button>
          </div>
        </Card>
        <Card variant="p12x16" color="bgGhost">
          <div>
            <Text variant="b1" block>
              5000 GC
            </Text>
            <Text variant="b5" color="typoPlaceholder" block>
              500 Руб.
            </Text>
            <Button
              className={classes.battlepassButton}
              onClick={() => onClickHandler(5000)}
            >
              Выбрать
            </Button>
          </div>
        </Card>
        <Card variant="p12x16" color="bgGhost">
          <div>
            <Text variant="b1" block>
              10000 GC
            </Text>
            <Text variant="b5" color="typoPlaceholder" block>
              1 000 Руб.
            </Text>
            <Button
              className={classes.battlepassButton}
              onClick={() => onClickHandler(10000)}
            >
              Выбрать
            </Button>
          </div>
        </Card>
      </div>
      <Text
        className={classes.textWarning}
        color="typoPlaceholder"
        variant="b5"
        block
      >
        Если у тебя возникли сложности, пиши в наш Telegram канал{' '}
        <a className={classes.discordLink} href="https://t.me/GameGreed">
          GameGreed
        </a>
      </Text>
    </div>
  );
};
