import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  withdrawFiatContainer: {
    backgroundColor: theme.palette.bg.ghost,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px',
    borderRadius: 8,
    marginBottom: '16px',
  },
  title: {
    marginTop: '10px',
  },
  textWarning: {
    margin: '20px 0',
  },
  text: {
    display: 'inline-block',
  },
  supportText: {
    marginTop: 20,
    marginBottom: 34,
  },
  battlepassBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    '&>div': {
      padding: 25,
    },
  },
  battlepassButton: {
    marginTop: '25px',
  },
  confirmContentAmount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.bg.ghost,
    padding: '20px 16px',
    borderRadius: 8,
    margin: '20px  0',
    gap: 30,
  },
  confirmContentFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  confirmContentBtn: {
    marginTop: 20,
    width: '100%',
  },
  discordLink: {
    color: theme.palette.typo.link,
  },
}));
